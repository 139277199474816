import {
	AccordionSummary as MuiAccordionSummary,
	AccordionDetails as MuiAccordionDetails,
	AccordionSummaryProps,
	styled,
} from '@mui/material';
import Iconify from 'src/components/Iconify';

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary expandIcon={<Iconify icon='material-symbols-light:play-arrow' fontSize={25} />} {...props} />
))({
	background: 'transparent',
	flexDirection: 'row-reverse',
	fontSize: '20px',
	fontWeight: 700,
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
});

export const AccordionDetails = styled(MuiAccordionDetails)({
	padding: '7px 20px',
	listStylePosition: 'inside',
});
