import { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectTransactionTracker } from 'src/redux/slices/transaction';
import {
	iconByTrackerStepStatus,
	labelByTrackerStage,
	labelByTrackerStepName,
	trackerStepStatus,
} from 'src/components/TransactionSummary/constants';
import { Box, Tooltip, Typography } from '@mui/material';
import { ActiveTransactionSummaryField } from 'src/components/TransactionSummary/styles';
import moment from 'moment';
import palette from 'src/theme/palette';
import { selectUser } from 'src/redux/slices/auth';
import Iconify from 'src/components/Iconify';
import { TrackerSectionProps } from './types';
import Task from '../Task';
import SummaryField from '../../../SummaryField';
import { trackerStagesByStep } from '../MoveTaskModal/constants';

const TrackerSection: FC<TrackerSectionProps> = ({ step, setIsLoading }) => {
	const tracker = useSelector(selectTransactionTracker);
	const user = useSelector(selectUser);

	if (!tracker.data) return null;

	const trackerStep = tracker.data[step];
	const label = labelByTrackerStepName[step];

	const statusParams = {
		[trackerStepStatus.notStarted]: { label: 'NOT STARTED', color: '#EEF1F3' },
		[trackerStepStatus.inProgress]: {
			label: `IN PROGRESS ${trackerStep.date ? `(ETA ${moment(trackerStep.date).format('DD/MM/YYYY')})` : ''}`,
			color: 'yellow',
		},
		[trackerStepStatus.pending]: {
			label: `DELAYED ${trackerStep.date ? `(ETA ${moment(trackerStep.date).format('DD/MM/YYYY')})` : ''}`,
			color: 'orange',
		},
		[trackerStepStatus.completed]: {
			label: `COMPLETED ${trackerStep.date ? `(${moment(trackerStep.date).format('DD/MM/YYYY')})` : ''}`,
			color: 'green',
		},
		[trackerStepStatus.error]: { label: `ERROR`, color: 'red' },
	};

	const status = statusParams[trackerStep.status];

	if (!step) return null;

	const stages = trackerStagesByStep[step];
	const isExchange = step === 'exchange';

	return (
		<Box>
			<ActiveTransactionSummaryField
				name={
					<Box display='flex' alignItems='center' gap='5px'>
						{label}
						{isExchange && (
							<Tooltip
								title={
									<Typography fontSize='13px'>
										Once an exchange date is agreed, enter it in the date field for the 'Exchange' task. This will
										automatically mark the 'Exchange date agreed' task as done. This ensures the system records both the
										exchange date and when it was agreed. The same applies to the 'Completion' task and date.
									</Typography>
								}
							>
								<Box lineHeight={0}>
									<Iconify icon='material-symbols:info-outline' fontSize='20px' />
								</Box>
							</Tooltip>
						)}
					</Box>
				}
				sx={{ borderTop: `2px solid ${palette.light.grey[400]}`, background: status.color }}
				titleSx={{
					borderRight: `2px solid ${palette.light.grey[400]}`,
					marginRight: '7px',
					flex: 0.622,
					pr: '10px',
				}}
				childrenSx={{ flex: 0.3 }}
			>
				<Typography variant='h6'>{status.label}</Typography>
			</ActiveTransactionSummaryField>
			{stages.length
				? stages.map((s) => {
						const stage = trackerStep.stages[s];

						if (!stage) return null;

						const stageName = labelByTrackerStage[s];
						const tasks = trackerStep.tasks.filter(
							(t) => t.stage === s && (user.isAdmin || t.users.some((u) => u.id === user.uid)),
						);

						return (
							<>
								<SummaryField
									name={`${iconByTrackerStepStatus[stage.status]} ${stageName}`}
									sx={{ background: '#DCDCDC' }}
								/>
								{tasks.map((t) => (
									<Task key={t.id} {...t} setIsLoading={setIsLoading} />
								))}
							</>
						);
				  })
				: trackerStep.tasks.map((t) => <Task key={t.id} {...t} setIsLoading={setIsLoading} />)}
		</Box>
	);
};

export default TrackerSection;
