import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import discussQuote from 'src/api/quote/discuss-quote';
import Page from 'src/components/Page';
import { getQuoteThunk, selectQuote } from 'src/redux/slices/quoteSlice';
import { dispatch } from 'src/redux/store';
import { Box } from '@mui/system';
import Progress from 'src/components/Progress';
import { Accordion, Button, Typography } from '@mui/material';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { AuthPage } from '../auth/styles';
import { quoteTransactionStatus } from '../Quote/constants';
import { QuoteSection } from '../Quote/styled';
import ReportMissingQuoteAddressModal from '../Quote/components/ReportMissingQuoteAddressModal';
import QuoteQuestionsModal from './components/QuoteQuestionsModal';
import QuoteMeetingModal from './components/QuoteMeetingModal';
import QuoteCallbackModal from './components/QuoteCallbackModal';
import QuoteMessageModal from './components/QuoteMessageModal';
import { AccordionSummary, AccordionDetails } from './styled';

const DiscussQuote = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const quote = useSelector(selectQuote);

	const quoteId = searchParams.get('id');
	const token = searchParams.get('token');

	useEffect(() => {
		const setup = async () => {
			try {
				console.log('quoteId', quoteId);
				console.log('token', token);

				if (!quoteId || !token) {
					navigate('/auth/login', { replace: true });

					return;
				}

				await discussQuote({ id: quoteId, token });

				dispatch(getQuoteThunk({ id: quoteId, withLoading: true }));
			} catch (e) {
				console.log('e', e);

				navigate('/auth/login', { replace: true });
			}
		};

		setup();
	}, [quoteId, token]);

	const handleLogin = () => navigate(`/auth/login`);
	const handleReady = () => {
		sessionStorage.setItem('quoteId', quoteId as string);

		navigate(`/quote/${quoteId}`);
	};

	const handleHaveQuestions = () => {
		dispatch(openModal({ name: modals.quoteQuestions }));
	};

	const areAllTransactionsSubmitted = !!quote.data?.transactions.every(
		(t) => t.status === quoteTransactionStatus.submitted,
	);

	return (
		<Page title='Quote'>
			<AuthPage sx={{ alignItems: 'flex-start', py: '100px', overflowY: 'scroll' }}>
				<Box display='flex' alignItems='center' flexDirection='column'>
					{!quote.isInitialized ? (
						<Progress />
					) : quote.error || !quote.data ? (
						<Box display='flex' flexDirection='column' gap='10px' alignItems='center'>
							<Typography color='red' fontSize='16px' fontWeight={600}>
								{quote.error?.message ?? 'Something went wrong'}
							</Typography>
							<Button onClick={handleLogin} variant='contained'>
								Go to Login
							</Button>
						</Box>
					) : (
						<Box display='flex' flexDirection='column' gap='15px' maxWidth='750px' minWidth='750px'>
							<QuoteSection>
								<Typography variant='h4'>FAQs</Typography>
							</QuoteSection>
							<QuoteSection sx={{ py: '20px' }}>
								<Accordion>
									<AccordionSummary>Why Choose Conveyo?</AccordionSummary>
									<AccordionDetails>
										At Conveyo, we’re transforming property transactions – whether you’re buying or selling, we make the
										process faster, simpler, and stress-free.
										<br />
										<br />
										<ul>
											<li>
												<b>Transparent Pricing</b> – No hidden costs, just clear, fixed fees.
											</li>
											<li>
												<b>No Move, No Fee</b> – If your transaction falls through, you don’t pay legal fees.
											</li>
											<li>
												<b>Highly Rated Service</b> – Trusted by thousands, with 5* reviews on Trustpilot and Google.
											</li>
											<li>
												<b>Only the Best Professionals</b> – We work with vetted experts to support your journey.
											</li>
											<li>
												<b>Designed Around You</b> – A modern, client-friendly experience that saves you time.
											</li>
											<li>
												<b>Added Perks</b> – Enjoy free tools and services to streamline your transaction.
											</li>
										</ul>
									</AccordionDetails>
								</Accordion>
								<Accordion>
									<AccordionSummary>What Does Conveyo Actually Do?</AccordionSummary>
									<AccordionDetails>
										When you're <b>buying or selling a property</b>, there’s a lot to sort out - solicitors,
										certificates, banking checks, and more. Instead of leaving you to figure it all out,{' '}
										<b>Conveyo handles everything for you.</b>
										<br />
										<br />
										We provide:
										<br />
										<br />
										<div>
											<>
												✅ <b>A Trusted Solicitor</b> – We connect you with a vetted conveyancer who handles all the
												legal aspects of your transaction.
											</>
											<br />
											<>
												✅ <b>All Evidence You Need</b> – We arrange your AML (anti-money laundering) checks, ID
												verification, source of funds, and property searches — ensuring everything is in place for a
												smooth transaction.
											</>
											<br />
											<>
												✅ <b>A Managed Process</b> – We coordinate between you, your estate agent, your solicitor, and
												your counterparty, making sure everyone is aligned, documents are in order, and things move
												forward without unnecessary delays.
											</>
											<br />
											<>
												✅ <b>Support Every Step of the Way</b> – Got questions? Need help? We’re here to guide you.
											</>
											<br />
											<>
												✅ <b>Additional Services</b> – From surveys to estate planning and anything else required, we
												offer the services you need to make your move even easier.
											</>
											<br />
											<br />
											<b>Think of us as your personal transaction concierge</b> - we arrange everything and keep things
											moving, so you can focus on living your life.
										</div>
									</AccordionDetails>
								</Accordion>
								<Accordion>
									<AccordionSummary>What’s Included in My Quote?</AccordionSummary>
									<AccordionDetails>
										Your <b>quote</b> includes all the essential services and standard disbursements required for a
										smooth property transaction, such as:
										<br />
										<br />
										<ul>
											<li>
												<b>Legal Fees</b> – Covering the legal work provided by your solicitor.
											</li>
											<li>
												<b>Searches</b> – Including property, local authority, and environmental searches.
											</li>
											<li>
												<b>Bank Transfers</b> – Any necessary bank fees related to transfers.
											</li>
											<li>
												<b>ID Checks</b> – Anti-money laundering (AML) checks and identity verification.
											</li>
											<li>
												<b>Official Copies</b> – Of key documents like the title deeds.
											</li>
											<li>
												<b>Gift Declarations</b> – If applicable, related to any gifts used in the purchase.
											</li>
										</ul>
										<br />
										These are all considered <b>standard disbursements</b> that are included in your quote, so there are
										no hidden costs. The goal is to provide full transparency and ensure everything is covered for you.
									</AccordionDetails>
								</Accordion>
								<Accordion>
									<AccordionSummary>Do I Need to Put Any Money on Account? When is Payment Due?</AccordionSummary>
									<AccordionDetails>
										Your solicitor may ask you to put a <b>small amount</b> (typically <b>£50-£100</b>) on account. This
										amount is already included in your quote, so you don’t need to worry about it as an additional cost.
										<br />
										<br />
										If you're selling a <b>managed property</b>, you’ll need to pay for the <b>management pack </b>
										directly to your management company. Prices for this can vary, typically ranging from
										<b> £100 to £500.</b>
										<br />
										<br />
										The <b>total amount quoted</b> by Conveyo is invoiced in <b>two parts:</b>
										<br />
										<br />
										<ol>
											<li>
												<b>At Exchange</b> – Conveyo issues an invoice for the <b>first part</b> of the fees. This
												covers our service, including managing the process, handling data and documents, reviewing them,
												and coordinating between all parties involved in your transaction.
											</li>
											<br />
											<li>
												<b>At Completion</b> – The <b>second part</b> of the fee is invoiced directly by your{' '}
												<b>solicitor. </b>
												This covers their legal work, provided at the <b>special rate</b> we’ve negotiated for you.
											</li>
										</ol>
										<br />
										Together, these two amounts make up the <b>total quote</b> you receive from Conveyo.
									</AccordionDetails>
								</Accordion>
								<Accordion>
									<AccordionSummary>Why Are You Asking Me to Pay Extra on Top of the Solicitor’s Fee?</AccordionSummary>
									<AccordionDetails>
										The <b>Conveyo fee</b> is <b>not an add-on</b> to the solicitor’s fee. The <b>total quote</b> you
										receive from Conveyo is the <b>same</b> amount you would typically pay for conveyancing at the
										average market rate. Our goal is to ensure that <b>our innovation</b>—the convenience, coordination,
										and added value we provide—comes at <b>no extra cost</b> to you.
										<br />
										<br />
										We’ve negotiated a <b>better rate</b> for your solicitor’s services, and on top of that, we manage
										the entire process, handling everything from coordination to ensuring all parties are aligned. This
										level of service is something a typical solicitor wouldn’t provide.
										<br />
										<br />
										So, while you may wonder if you could simply pay the solicitor’s fee alone,{' '}
										<b>Conveyo offers much more</b>—a complete process that takes the hassle out of property
										transactions, all for the same price you'd expect to pay for traditional conveyancing.
									</AccordionDetails>
								</Accordion>
								<Accordion>
									<AccordionSummary>I'm Selling – Why Are You Asking Me to Purchase the Searches?</AccordionSummary>
									<AccordionDetails>
										We <b>recommend</b> that all sellers purchase the searches as soon as possible. Here's why:
										<br />
										<br />
										<ul>
											<li>
												<b>Time-Saving</b> – The searches last for a year and will be passed on to your buyer, saving up
												to <b>8 weeks</b> in the process.
											</li>
											<li>
												<b>Boosting Confidence</b> – Having these searches ready <b>in advance</b> shows your buyer that
												you’re prepared and can help speed up the sale.
											</li>
											<li>
												<b>Guaranteed Acceptance</b> – The searches we recommend are exactly the same as those your
												buyer’s solicitor would obtain. If your buyer uses <b>Conveyo,</b> there’s a{' '}
												<b>100% guarantee</b> they’ll accept the searches.
											</li>
											<li>
												<b>Widely Accepted</b> – Even if your buyer uses a different solicitor, <b>95% of buyers</b> opt
												to purchase our searches.
											</li>
											<li>
												<b>Risk-Free</b> – We’re so confident that your buyer will accept the searches, we{' '}
												<b>guarantee </b>
												them—<b>if your buyer doesn’t use them,</b> we will <b>refund the cost</b> of the searches.
											</li>
										</ul>
										<br />
										Purchasing the searches upfront gives you a competitive edge and helps ensure a smoother, faster
										sale.
									</AccordionDetails>
								</Accordion>
								<Accordion>
									<AccordionSummary>How Quickly Can I Expect to Complete?</AccordionSummary>
									<AccordionDetails>
										With <b>Conveyo,</b> the process takes just <b>30 days</b> from sale agreed to exchange, compared to
										a UK national average of <b>22 weeks</b>.
										<br />
										<br />
										While we can’t control every aspect, such as the performance of chain solicitors we don’t work with
										or changes in personal circumstances, what we <b>can guarantee</b> is that with <b>Conveyo,</b>{' '}
										we’re <b>maximising your chances</b> of a smooth, fast transaction. We proactively manage the key
										steps and minimise delays, so you can be confident that you’re on the <b>fastest track</b> possible.
										<br />
										<br />
										At the end of the day, <b>Conveyo is built for speed,</b> and we’re committed to making your
										property transaction as quick, easy, and certain as possible.
									</AccordionDetails>
								</Accordion>
							</QuoteSection>
							<Box display='flex' gap='15px' alignItems='center' justifyContent='center'>
								<Button
									onClick={handleReady}
									variant='contained'
									sx={{
										mt: '15px',
										padding: '12px 24px', // Chunky button
										fontSize: '18px',
										textTransform: 'none', // Enforce sentence case
									}}
									disabled={!areAllTransactionsSubmitted}
								>
									I'm ready!
								</Button>

								<Button
									onClick={handleHaveQuestions}
									variant='outlined'
									sx={{
										mt: '15px',
										padding: '12px 24px', // Chunky button
										fontSize: '18px',
										textTransform: 'none', // Enforce sentence case
									}}
								>
									I still have questions
								</Button>
							</Box>
						</Box>
					)}
				</Box>
			</AuthPage>
			<ReportMissingQuoteAddressModal />
			<QuoteQuestionsModal />
			<QuoteMeetingModal />
			<QuoteCallbackModal />
			<QuoteMessageModal />
		</Page>
	);
};

export default DiscussQuote;
