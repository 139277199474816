import { UserData } from 'src/api/auth/@user-data';
import { ITransactionTracker } from 'src/redux/types';

const trackerStep = {
	seller: 'seller',
	property: 'property',
	buyer: 'buyer',
	legals: 'legals',
	listing: 'listing',
	exchange: 'exchange',
};

export const getTabs = (user: UserData, tracker: ITransactionTracker) => [
	...[
		{ value: trackerStep.seller, label: 'Sellers' },
		{ value: trackerStep.property, label: 'Property' },
		{ value: trackerStep.listing, label: 'Listing' },
		{ value: trackerStep.buyer, label: 'Buyers' },
		{ value: trackerStep.legals, label: 'Legals' },
		{ value: trackerStep.exchange, label: 'Closing' },
	].filter((tab) => user.isAdmin || tracker[tab.value].tasks.some((t) => t.users.some((u) => u.id === user.uid))),
	...(user.isAdmin ? [{ value: 'deleted', label: 'Deleted' }] : []),
];
